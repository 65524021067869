<template>
    <div>
        <ul class="p-0" v-if="workingCopies.files.length">
            <li class="d-flex" v-for="(file, key) in workingCopies.files" :key="key" :class="listFileClasses(file)">
                <div class="flex-grow-1">
                    <div class="list-file__filename">{{file.originalFilename}}</div>
                    <div data-e2e="fileUploaderStatus" class="text-mini" v-show="file.status">Status: {{file.status | beautify}}</div>
                    <mercur-progress-bar v-if="file.status === 'pending' || file.status === 'completed'" :value="file.percentCompleted"></mercur-progress-bar>
                </div>
                <mercur-button class="btn btn-icon" v-if="!isDirectUpload" @click.native="removeFile(key, file.objectName)"><i class="fas fa-times"></i></mercur-button>
            </li>
        </ul>
        <file-area :uploadUrl="uploadUrl" @uploadUpdate="uploadUpdate" :isDirectUpload="isDirectUpload"></file-area>
    </div>
</template>

<script>
import FileArea from '@/components/utils/filedropper/FileArea'

export default {
    name: 'Filedropper',
    components: { FileArea },
    data () {
        return {
            highlighted: false,
            workingCopies: {
                files: [],
            },
        }
    },
    props: {
        uploadUrl: {
            type: String,
        },
        isDirectUpload: {
            default: false,
        },
    },
    methods: {
        uploadUpdate ($event) {
            const file = $event.file

            if ($event.type === 'start') {
                this.workingCopies.files.unshift({
                    ...file,
                    status: 'pending',
                    percentCompleted: 0,
                })

                return
            }

            let listFile
            if (file && file.objectName) {
                listFile = this.workingCopies.files.find(workingFile => file.objectName === workingFile.objectName)
            }

            if ($event.type === 'completed') {
                this.$emit('uploaded', $event)
                this.$set(listFile, 'status', 'completed')
                setTimeout(() => {
                    this.$set(listFile, 'status', null)
                }, 3000)
                return
            }

            if ($event.type === 'finished') {
                this.$emit('finished', $event)
            }

            if ($event.type === 'progress') {
                this.$set(listFile, 'percentCompleted', $event.percentCompleted)
            }
        },
        listFileClasses (file) {
            let classes = []
            if (file.status === 'pending' || file.status === 'completed') {
                classes.push(`is-${file.status}`)
            }

            return classes.map(modifier => `list-file--${modifier}`)
        },
        removeFile (key, objectName) {
            this.workingCopies.files.splice(key, 1)
            this.$emit('fileRemoved', objectName)
        },
        resetFiles () {
            this.$set(this.workingCopies, 'files', [])
        },
    },

}
</script>
