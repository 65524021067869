<template>
    <mercur-dialog :is-open.sync="isOpen" @close="resetForm">
        <h3 class="font-weight-normal">Edit quote item</h3>
        <template v-if="form.product">
            <div class="d-flex justify-content-between">
                <mercur-input type="number" step="any" v-model.number="form.productPrice" :class="{ 'form-invalid': $v.form.productPrice.$invalid }">
                    Product price - <currency :value="fields.productPrice" :type="fields.currency"></currency>
                    <template #note>
                        <span class="form-error" v-if="!$v.form.productPrice.required">Field is required</span>
                    </template>
                </mercur-input>
                <mercur-input type="number" step="any" v-model.number="form.productVatRate" :class="{ 'form-invalid': $v.form.productVatRate.$invalid }">
                    Product vat rate - {{ fields.productVatRate }}
                    <template #note>
                        <span class="form-error" v-if="!$v.form.productVatRate.required">Field is required</span>
                        <span class="form-error" v-if="!$v.form.productVatRate.between">Must be between 0 and 1</span>
                    </template>
                </mercur-input>
            </div>
            <div class="d-flex justify-content-between">
                <mercur-input type="number" step="any" v-model.number="form.shippingPrice" :class="{ 'form-invalid': $v.form.shippingPrice.$invalid }">
                    Shipping price - <currency :value="fields.shippingPrice" :type="fields.currency"></currency>
                    <template #note>
                        <span class="form-error" v-if="!$v.form.shippingPrice.required">Field is required</span>
                    </template>
                </mercur-input>
                <mercur-input type="number" step="any" v-model.number="form.shippingVatRate" :class="{ 'form-invalid': $v.form.shippingVatRate.$invalid }">
                    Shipping vat rate - {{ fields.shippingVatRate }}
                    <template #note>
                        <span class="form-error" v-if="!$v.form.shippingVatRate.required">Field is required</span>
                        <span class="form-error" v-if="!$v.form.shippingVatRate.between">Must be between 0 and 1</span>
                    </template>
                </mercur-input>
            </div>
            <mercur-input type="date" singleDatePicker :class="getValidationClass($v, ['deliveryInfo', 'targetDispatchDate'])" v-model="form.deliveryInfo.targetDispatchDate" :disabled-dates="disabledDeliveryDates">
                <label>Select target dispatch date</label>
                <span class="form-error" v-if="!$v.form.deliveryInfo.targetDispatchDate.validDate">Dispatch date is required</span>
            </mercur-input>
            <mercur-input type="date" :class="getValidationClass($v, ['deliveryInfo', 'targetDeliveryDate'])" v-model="form.deliveryInfo.targetDeliveryDate" :disabled-dates="disabledDeliveryDates">
                <label>Select target delivery date</label>
                <span class="form-error" v-if="!$v.form.deliveryInfo.targetDeliveryDate.validDate">Delivery date is required</span>
                <span class="form-error" v-if="!$v.form.deliveryInfo.targetDeliveryDate.minValue">Delivery date should be after dispatch date</span>
            </mercur-input>
            <mercur-input type="date" v-model="form.deliveryInfo.desiredDeliveryDate" :disabled-dates="disabledDeliveryDates">
                <label>Select desired delivery date</label>
            </mercur-input>
        </template>
        <template #footer>
            <mercur-button :disabled="loading" @click.native.prevent="isOpen = false" class="btn">Close</mercur-button>
            <mercur-button :disabled="loading" @click.native.prevent="save" class="btn btn-primary">Save</mercur-button>
        </template>
    </mercur-dialog>
</template>

<script>
import { required, between, minValue } from 'vuelidate/lib/validators'
import FormMixin from '@/mixins/Form'
import DialogLogicMixin from '@/mixins/DialogLogic'
import moment from 'moment'
import format from 'date-fns/format'
const validDate = (value) => moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD') === value

export default {
    name: 'EditQuoteDialog',
    mixins: [ DialogLogicMixin, FormMixin ],
    data () {
        const now = new Date()
        const dateFormat = 'yyyy-MM-dd'
        return {
            disabledDeliveryDates: date => {
                const day = date.getTime()
                const currentDay = new Date().getTime()
                return day <= currentDay
            },
            form: {
                deliveryInfo: {
                    targetDispatchDate: format(now, dateFormat),
                    targetDeliveryDate: format(now, dateFormat),
                    desiredDeliveryDate: format(now, dateFormat),
                },
            },
        }
    },
    computed: {
        mdType () {
            return String
        },
    },
    validations: {
        form: {
            productPrice: { required, minValue: minValue(0) },
            productVatRate: { required, between: between(0, 1) },
            shippingPrice: { required, minValue: minValue(0) },
            shippingVatRate: { required, between: between(0, 1) },
            deliveryInfo: {
                targetDispatchDate: {
                    required,
                    validDate,
                },
                targetDeliveryDate: {
                    required,
                    validDate,
                    minValue: function (value) {
                        if (!this.$v.form.deliveryInfo.targetDeliveryDate.validDate) {
                            return true
                        }
                        if (!this.$v.form.deliveryInfo.targetDispatchDate.validDate) {
                            return true
                        }
                        return moment(value).isAfter(moment(this.form.deliveryInfo.targetDispatchDate))
                    },
                },
            },
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-quote-edit__datepicker {
        width: 33.33%;
    }
</style>
