<template>
    <div class="c-quote-edit">
        <v-title title="Quotes - Edit"></v-title>

        <title-box>
            <div class="row">
                <div class="col-sm-10">
                    <h1 v-if="quote">Edit quote: {{ quote.quoteNumber }}
                        <span class="c-order-view__title-span"><status-chip data-e2e="editQuoteStatus" v-if="quote.status">{{ quote.status }}</status-chip></span>
                    </h1>
                </div>
                <div class="col-sm-2 text-right">
                    <mercur-menu data-e2e="editQuoteActionMenu">
                        <mercur-button class="btn">Actions</mercur-button>
                        <div slot="dropdown">
                            <mercur-item v-if="hasPermission('MerchantCentral/approveQuote')" @click.native.prevent="approveQuote" :disabled="loading || quote.status === 'APPROVED' || quote.status === 'CHECKED_OUT'|| isLocked">Approve Quote</mercur-item>
                            <mercur-item v-if="hasPermission('MerchantCentral/rejectQuote')" @click.native.prevent="rejectQuote" :disabled="loading || quote.status === 'CHECKED_OUT'|| isLocked">Reject Quote</mercur-item>
                            <mercur-item v-if="hasPermission('MerchantCentral/acceptQuote')" @click.native.prevent="acceptQuote" :disabled="loading || quote.status !== 'APPROVED' || quote.status === 'CHECKED_OUT' || isLocked">Create order from Quote</mercur-item>
                            <mercur-item data-e2e="editQuoteShareSupplierOption" @click.native.prevent="shareWithSupplier" :disabled="loading || isLocked || quote.status === 'AWAITING_MERCHANT' || quote.status === 'APPROVED'">Share quote with supplier</mercur-item>
                            <mercur-item @click.native.prevent="downloadQuotePDF" :disabled="!isQuotePDFAvailable">Download quote as PDF</mercur-item>
                        </div>
                    </mercur-menu>
                </div>
            </div>
        </title-box>

        <div class="container-fluid" v-if="Object.keys(quote).length">
            <div class="row">
                <div class="col-12">
                    <mercur-card class="mt-n4 mb-4">
                        <h3 class="font-weight-normal">Products <mercur-button class="btn btn-icon btn-icon-mini text-white btn-yellow" v-if="hasPermission('MerchantCentral/addItemToCart')" @click.native.prevent="showFunnelDialog = true" :disabled="quote.status === 'CHECKED_OUT'|| isLocked"><i class="fas fa-plus"></i></mercur-button></h3>
                        <merchant-data-table v-if="rowData" style="min-height: unset; height: auto;" class="c-merchant-table--dense" ref="table" :items="rowData" :options="options"></merchant-data-table>
                        <p v-else>No products added to this quote</p>
                    </mercur-card>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mercur-card class="c-quote-edit__attachments mb-4">
                        <h3 class="font-weight-normal">Attachments</h3>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>File Name</th>
                                    <th>Buyer</th>
                                    <th>Merchant</th>
                                    <th>Quotation team</th>
                                    <th>Uploader</th>
                                    <th>Date created</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="attachment in quote.attachments" :key="attachment.dateCreated">
                                    <td><span @click="downloadAttachment(attachment.objectName)">{{ attachment.originalFileName }}</span></td>
                                    <td><mercur-checkbox v-model="attachment.permissionBuyer" :disabled="loading || attachment.uploaderType === 'BUYER'" @change="setQuoteAttachmentPermission(attachment, 'BUYER', $event)"></mercur-checkbox></td>
                                    <td><mercur-checkbox v-model="attachment.permissionMerchant" :disabled="loading || attachment.uploaderType === 'MERCHANT' || attachment.uploaderType === 'BUYER'" @change="setQuoteAttachmentPermission(attachment, 'MERCHANT', $event)"></mercur-checkbox></td>
                                    <td><mercur-checkbox v-model="attachment.permissionVirtualSupplier" :disabled="loading || attachment.uploaderType === 'VIRTUAL_SUPPLIER'" @change="setQuoteAttachmentPermission(attachment, 'VIRTUAL_SUPPLIER', $event)"></mercur-checkbox></td>
                                    <td>{{ getAttachmentUploaderName(attachment)}}</td>
                                    <td>{{ attachment.dateCreated }}</td>
                                    <td><mercur-button class="btn btn-icon" @click.native.prevent="removeAttachment(attachment)" :disabled="loading"><i class="fas fa-trash"></i></mercur-button></td>
                                </tr>
                            </tbody>
                        </table>
                        <quote-uploader @uploaded="attachToCart" ref="uploader" :customer="customer"></quote-uploader>
                    </mercur-card>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    <div v-if="Object.keys(customer).length">
                        <h3 class="d-flex align-items-center">
                            <span class="flex-grow-1">Addresses for {{ customer.firstName }} {{ customer.lastName }}</span>
                            <mercur-button v-if="hasPermission('MerchantCentral/addAddress')" class="btn btn-primary text-uppercase" @click.native.prevent="triggerAddAddress">add new address</mercur-button>
                        </h3>
                        <customer-addresses
                            class="mb-4"
                            :customOptions="customOptions"
                            :organisationId="customer.organisation.organisationId"
                            ref="addresses"
                            :customerId="customer.accountId"
                            :canSetDefault="true"
                            :isNotEditable="true"
                            @setBilling="setCartBillingInfo"
                            @setShipping="setShippingAddressForCartItems"
                            :disabled="isLocked"
                        >
                        </customer-addresses>
                    </div>
                </div>
                <div class="col-4">
                    <mercur-card v-if="quote.additionalCartInfo" class="mb-4">
                        <h3 class="c-card__title">Customer Requirements</h3>
                        <div>
                            <mercur-textarea v-model="quote.additionalCartInfo.customerRequirements">
                                Customer Requirements
                            </mercur-textarea>
                            <div class="text-right">
                                <mercur-button @click.native.prevent="updateQuote()" :disabled="loading || isLocked" class="btn btn-primary">Save</mercur-button>
                            </div>
                        </div>
                    </mercur-card>
                    <mercur-card class="mb-4">
                        <customer-info :billingAddress="billingAddress" :isNotEditable="true"></customer-info>
                    </mercur-card>
                    <mercur-card class="mb-4">
                        <assigned-agent v-model="quote.quoteAgentAssigneeId"></assigned-agent>
                        <div class="text-right">
                            <mercur-button @click.native.prevent="updateQuote()" :disabled="loading" class="btn btn-primary">Save</mercur-button>
                        </div>
                    </mercur-card>
                </div>
            </div>
        </div>
        <edit-quote-dialog :fields="draft.cartItem" ref="editDialog" @change="updateQuoteItem" @closed="unsetItem">Edit quote item</edit-quote-dialog>

        <mercur-dialog :is-open.sync="showFunnelDialog" @close="draft = {}" width="70vw" height="90vh">
            <h3 class="font-weight-normal">Add product to quote</h3>
            <div class="row">
                <div class="col">
                    <funnel ref="funnel" @productAdded="addQuoteItemToCart" :country="quoteCountry" :is-free-fill-attributes="true" :isCustomizable="true"></funnel>
                </div>
                <div class="col-3" v-if="draft.copiedItem">
                    <div class="c-quote-edit__item-info">
                        <h3>Item being copied</h3>
                        <ul>
                            <li><strong>Product name:</strong> {{ draft.copiedItem.product.productName }}</li>
                            <br>
                            <li><strong>Attributes:</strong>
                                <ul>
                                    <li v-for="attribute in draft.copiedItem.product.attributes" :key="attribute.attributeName"><strong>{{ attribute.attributeName }}:</strong> {{ attribute.attributeOption }}</li>
                                </ul>
                            </li>
                            <li><strong>Quantity:</strong> {{ draft.copiedItem.product.quantity }}</li>
                            <br>
                            <li><strong>Desired delivery date:</strong> {{ draft.copiedItem.deliveryInfo.desiredDeliveryDate }}</li>
                            <li><strong>Desired quantity:</strong> {{ draft.copiedItem.product.additionalProductInfo.quoteQuantity }}</li>
                            <br>
                            <li><strong>Customer requirements: </strong><br><span>{{ quote.additionalCartInfo.customerRequirements }}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </mercur-dialog>

        <div>
            <mercur-dialog :is-open.sync="isOrderlinesDialog" width="600px">
                <template v-if="!items.cartItems">
                    <h3 class="font-weight-normal">Please first add a cartItem before setting the delivery address</h3>
                </template>
                <template v-else>
                    <h3 class="font-weight-normal">Select the cartItems to change delivery address</h3>
                    <table class="table">
                        <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>Quote Item ID</th>
                            <th>Product</th>
                            <th>Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="cartItem in items.cartItems" :key="cartItem.cartItemId">
                            <td><mercur-checkbox v-model="selectedCartItems" :value="cartItem"></mercur-checkbox></td>
                            <td>{{ cartItem.cartItemId }}</td>
                            <td>{{ cartItem.product ? cartItem.product.productName : '' }}</td>
                            <td>{{ cartItem.productPrice }}</td>
                        </tr>
                        </tbody>
                    </table>
                </template>
                <template #footer>
                    <mercur-button class="btn" @click.native.prevent="isOrderlinesDialog = false" :disabled="loading">Close</mercur-button>
                    <mercur-button class="btn btn-primary" @click.native.prevent="setAddresses" :disabled="loading">Save</mercur-button>
                </template>
            </mercur-dialog>
        </div>

        <mercur-dialog :is-open.sync="isAddressAddDialogOpen">
            <address-form ref="addressForm" :options="addressFormOptions"  @success="onAddedAddress" />
        </mercur-dialog>
    </div>
</template>
<script>
import ResourcesView from '@/views/ResourcesView'
import CONFIG from '@root/config'
import MerchantDataTable from '@/components/elements/MerchantDataTable'
import AttributeList from '@/components/elements/order/AttributeList'
import DeliveryAddress from '@/components/elements/order/DeliveryAddress'
import CustomerInfo from '@/components/elements/order/CustomerInfo'
import CustomerAddresses from '@/components/elements/customers/Addresses'
import FormMixin from '@/mixins/Form'
import { mapActions, mapGetters, mapState } from 'vuex'
import StatusChip from '@/components/utils/StatusChip'
import Funnel from '@/components/elements/Funnel'
import EditQuoteDialog from '@/components/elements/quotes/EditDialog'
import Currency from '@/components/elements/Currency'
import QuoteUploader from '@/components/elements/quotes/Uploader'
import AddressForm from '../../components/elements/AddressForm'
import AssignedAgent from '../../components/elements/agents/AssignedAgent'
import countries from 'country-region-data'

export default {
    name: 'EditQuote',
    mixins: [ResourcesView, FormMixin],
    components: { AssignedAgent, AddressForm, CustomerInfo, StatusChip, Funnel, MerchantDataTable, CustomerAddresses, EditQuoteDialog, QuoteUploader },
    data () {
        return {
            countries,
            draft: {},
            loading: false,
            isOrderlinesDialog: false,
            isAddressAddDialogOpen: false,
            addressFormOptions: null,
            customOptions: {
                paginationType: 'native',
            },
            url: null,
            billingUrl: CONFIG.API.ROUTES.CART.BILLING_INFO.replace('{accountId}', this.$route.params.customerId).replace('{cartId}', this.$route.params.cartId),
            billingAddress: {},
            shippingUrl: CONFIG.API.ROUTES.CART.ITEM_DELIVERY_INFO.replace('{accountId}', this.$route.params.customerId).replace('{cartId}', this.$route.params.cartId),
            showFunnelDialog: false,
            selectedCartItems: null,
            options: {
                columns: {
                    'Product': {
                        field: 'product.productName',
                        cellRendererParams: (params) => {
                            return `<b>${params.data.product.productName}</b>`
                        },
                    },
                    'Sku': {
                        field: 'product.sku',
                        cellRendererParams: (params) => {
                            return params.data.product.sku
                        },
                    },
                    'Price': {
                        field: 'productPrice',
                        width: 150,
                        cellRendererFramework: Currency,
                        cellRendererParams: (params) => {
                            return {
                                value: params.value,
                                type: this.items.currency,
                            }
                        },
                    },
                    'Shipping Price': {
                        field: 'shippingPrice',
                        width: 260,
                        cellRendererFramework: Currency,
                        cellRendererParams: (params) => {
                            return {
                                value: params.value,
                                type: this.items.currency,
                            }
                        },
                    },
                    'Product Cost': {
                        field: 'product.prices.productCost',
                        cellRendererFramework: Currency,
                        cellRendererParams: (params) => {
                            return {
                                value: params.value,
                                type: this.items.currency,
                            }
                        },
                    },
                    'Shipping Cost': {
                        field: 'product.prices.shippingCost',
                        cellRendererFramework: Currency,
                        cellRendererParams: (params) => {
                            return {
                                value: params.value,
                                type: this.items.currency,
                            }
                        },
                    },
                    'Attributes': { field: '', cellRendererFramework: AttributeList, width: 300 },
                    'Delivery address': { field: '', cellRendererFramework: DeliveryAddress, width: 300 },
                    'Target Dispatch date': { field: 'deliveryInfo.targetDispatchDate', width: 230 },
                    'Target Delivery date': { field: 'deliveryInfo.targetDeliveryDate', width: 230 },
                    'Desired Delivery date': { field: 'deliveryInfo.desiredDeliveryDate', width: 230 },
                    'Weight': {
                        field: 'product.productInfo.weight',
                        width: 150,
                    },
                    'Quote quantity': {
                        field: 'product.additionalProductInfo.quoteQuantity',
                        width: 160,
                    },
                },
                actions: {
                    type: 'dropdown',
                    items: [
                        {
                            text: 'Edit quote item',
                            onClick: ({ data }) => {
                                this.$set(this, 'draft', {
                                    cartItem: {
                                        ...data,
                                        currency: data.product.prices.currencyCode,
                                    },
                                })
                            },
                            isDisabled: () => {
                                return this.isLocked
                            },
                        },
                        {
                            text: 'Create new quote item from this',
                            onClick: ({ data }) => {
                                return this.createNewItemFromCurrent(data)
                            },
                            isDisabled: () => {
                                return this.isLocked
                            },
                        },
                        {
                            text: 'Delete quote item',
                            onClick: ({ data }) => {
                                this.removeItemFromCart(data.cartItemId)
                            },
                            isHidden: () => {
                                return !this.hasPermission('removeItemFromCart')
                            },
                            isDisabled: () => {
                                return this.isLocked
                            },
                        },
                    ],
                },
                paginationPageSize: 5,
                cacheBlockSize: 5,
                defaultColDef: { menuTabs: [] },
            },
            accountRequestQueue: {},
        }
    },
    watch: {
        quote () {
            if (this.quote.billingInfo) {
                this.billingAddress = this.quote.billingInfo.address
            }
        },
        response () {
            if (Array.isArray(this.items.additionalCartInfo)) {
                this.items.additionalCartInfo = {}
            }
        },
    },
    computed: {
        ...mapState('customer', ['customer']),
        ...mapGetters('shop', ['workingCountriesList']),
        ...mapState('shop', ['applicationVatProfiles']),
        isQuotePDFAvailable () {
            if (!this.loading && this.quote && this.quote.quoteFileName && this.quote.quoteUri) {
                return true
            }

            return false
        },
        quote () {
            return this.items
        },
        rowData () {
            return this.quote.cartItems
        },
        breadCrumbId () {
            if (!this.quote) {
                return '...'
            }
            return this.quote.quoteNumber || this.quote.quoteId
        },
        dateFormat () {
            return this.$material.locale.dateFormat || 'yyyy-MM-dd'
        },
        isLocked () {
            const lockedStates = ['AWAITING_QUOTATION', 'AWAITING_SUPPLIER']
            if (!this.quote.status) {
                return true
            }
            return lockedStates.includes(this.quote.status)
        },
        quoteCountry () {
            if (!this.quote) {
                return null
            }
            if (!this.quote.countryCode) {
                return null
            }
            return this.quote.countryCode.toUpperCase()
        },
    },
    methods: {
        ...mapActions('shop', ['checkShop', 'fetchCurrencyConversionRates', 'setShopById', 'fetchApplicationVatProfiles']),
        ...mapActions('customer', ['fetchCustomer']),
        downloadQuotePDF () {
            const url = CONFIG.API.ROUTES.CART.GET_QUOTE_PDF_DOWNLOAD_URL
            this.$root.$emit('notification:global', {
                message: 'Downloading file...',
            })
            this.post(url, {
                fileName: this.quote.quoteFileName,
                objectName: this.quote.quoteUri,
            }).then((data) => {
                window.open(data.url, '_blank')
            })
        },
        onAddedAddress () {
            this.isAddressAddDialogOpen = false
            this.$refs.addresses.$refs.table.refreshGrid()
        },
        triggerAddAddress () {
            this.addressFormOptions = {
                action: CONFIG.API.ROUTES.CUSTOMERS.ADDRESS.ADD
                    .replace('{customerId}', this.customer.accountId)
                    .replace('{organisationId}', this.customer.organisation.organisationId),
                title: 'Add new address',
                notification: 'Address added',
            }
            this.isAddressAddDialogOpen = true
        },
        getAttachmentUploaderName (attachment) {
            if (!attachment.uploadedByAccountId) {
                return attachment.uploaderType
            }
            const accountId = attachment.uploadedByAccountId

            if (!this.accountRequestQueue[accountId]) {
                this.$set(this.accountRequestQueue, accountId, new Promise((resolve, reject) => {
                    this.get(CONFIG.API.ROUTES.AGENTS.GET.replace('{agentId}', accountId)).then(({ data }) => {
                        this.$set(this.accountRequestQueue, accountId, `${data.firstName} ${data.infix || ''} ${data.lastName}`)
                    })
                }))
            }

            if (typeof this.accountRequestQueue[accountId] === 'string') {
                return this.accountRequestQueue[accountId]
            }

            return attachment.uploaderType
        },
        pushCustomerView () {
            setTimeout(() => {
                this.$router.push({
                    name: 'CustomerView',
                    params: {
                        customerId: this.$route.params.customerId,
                    },
                })
            }, 1000)
        },
        approveQuote () {
            const url = CONFIG.API.ROUTES.CART.APPROVE.replace('{accountId}', this.quote.buyer.accountId).replace('{cartId}', this.$route.params.cartId)
            if (this.quote.cartItems.some(item => !item.deliveryInfo.address)) {
                this.$root.$emit('notification:global', {
                    message: 'All items need to have delivery address',
                })
                return
            }

            if (this.quote.cartItems.some(item => parseFloat(item.productPrice) < 0.01)) {
                this.$root.$emit('notification:global', {
                    message: 'All items need to have product price',
                })
                return
            }

            if (this.quote.cartItems.some(item => !item.product.producer.supplierId)) {
                this.$root.$emit('notification:global', {
                    message: 'All items need to have a supplier set',
                })
                return
            }

            this.submit(url, {}).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Quote was approved',
                })
                this.getResources()

                const segmentProducts = []
                this.quote.cartItems.forEach((item, index) => {
                    segmentProducts.push({
                        product_id: item.product.name || item.product.productName || '',
                        sku: item.product.sku || '',
                        category: '', // todo // this.product.ContentCategory
                        name: item.product.name || item.product.productName || '',
                        brand: '',
                        variant: '',
                        price: item.productPrice,
                        quantity: item.product.quantity || item.product.attributes.printrun || 0,
                        coupon: '',
                        position: index,
                        url: item.product.additionalProductInfo.editLink || '',
                        // image_url: item.product.additionalProductInfo.image.src ? 'https:' + item.product.additionalProductInfo.image.src : '',
                    })
                })

                const firstOrderline = this.quote.cartItems[0]
                this.$segmentClient.sendAction(this, 'Send Quote', {
                    userId: this.quote.buyer.accountId,
                    tax: this.quote.totals.vat, // Total amount of tax to be paid for this order
                    total: this.quote.totals.total, // Total amount to be paid for this order
                    cartId: this.quote.cartId || '', // Unique identifier of the cart the user actually validatedc
                    coupon: '', // Was a coupon code used // !! Find it.
                    module: 'adyen', // External payment module used
                    orderId: this.quote.orderId || 'n/a on quote', // Unique identifier of the order
                    payment: 'adyen', // External payment module used
                    revenue: this.quote.totals.total, // Actual revenue made
                    currency: this.quote.currency, // Currency used
                    discount: this.quote.totals.reduction, // Total amount discounted if coupon code applied. This filled could be 0
                    order_id: this.quote.orderId || 'n/a on quote', // Unique identifier of the order
                    products: segmentProducts, // Json file contening all the products specific information for this specific order (category, price, quantity, finish...)
                    shipping: this.quote.totals.shippingPrice, // Shipping cost
                    orderdate: this.quote.dateCreated, // Date of the order
                    discountWt: '', // Discount value including taxes
                    proof_link: '', // Link to validate the proof
                    shippingWt: (Number.parseFloat(this.quote.totals.shippingPrice) + Number.parseFloat(this.quote.totals.shippingVat)).toFixed(2).toString(), // Shipping cost including taxes
                    affiliation: this.quote.source || '', // Website on which the order was placed !!
                    paymentLink: '', // Link for direct payment
                    productCount: this.quote.cartItems.length, // Number of items for this order
                    totalProducts: this.quote.totals.productsPrice, // Total cost of products
                    invoiceAddress: 'n/a todo',
                    deliveryAddress: firstOrderline.deliveryInfo.address, // Delivery address to be used
                    totalProductsWt: (parseFloat(this.quote.totals.productsPrice) + parseFloat(this.quote.totals.productsVat)).toFixed(2).toString(), // Total amount including taxes
                    deliveryAddressHtml: '', // Formatted delivery address
                })
            })
        },
        rejectQuote () {
            const url = CONFIG.API.ROUTES.CART.REJECT.replace('{accountId}', this.quote.buyer.accountId).replace('{cartId}', this.$route.params.cartId)
            this.submit(url, {}).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Quote was rejected',
                })
                this.pushCustomerView()
            })
        },
        acceptQuote () {
            const url = CONFIG.API.ROUTES.CART.ACCEPT.replace('{accountId}', this.quote.buyer.accountId).replace('{cartId}', this.$route.params.cartId)
            this.submit(url, {}).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Order was created from quote',
                })
                this.pushCustomerView()
            })
        },
        async addQuoteItemToCart (product, productPrice = null) {
            const item = {
                product: product,
                productPrice: product.turnaround ? product.turnaround.price : product.prices.currencySpecificPrices[this.$store.getters['shop/shopCurrency']].salesPrice,
                shippingPrice: product.turnaround ? product.turnaround.shippingPrice : product.prices.currencySpecificPrices[this.$store.getters['shop/shopCurrency']].serviceLevelCost,
                productVatRate: this.selectedShop.configuration.vat ? this.selectedShop.configuration.vat.products : '0.23',
                shippingVatRate: this.selectedShop.configuration.vat ? this.selectedShop.configuration.vat.shipping : '0.23',
            }

            if (productPrice) {
                item.productPrice = productPrice
            }

            if (product.deliveryInfo) {
                item.deliveryInfo = {
                    deliveryDate: product.deliveryInfo.targetDeliveryDate,
                    targetDeliveryDate: product.deliveryInfo.targetDeliveryDate,
                    dispatchDate: product.deliveryInfo.targetDispatchDate,
                    targetDispatchDate: product.deliveryInfo.targetDispatchDate,
                }
            }

            if (item.product.deliveryInfo) {
                delete item.product.deliveryInfo
            }

            if (this.draft.copiedItem) {
                item.deliveryInfo = { ...this.draft.copiedItem.deliveryInfo, ...item.deliveryInfo }
                item.product.additionalProductInfo = { ...this.draft.copiedItem.product.additionalProductInfo, ...item.product.additionalProductInfo }
            }

            this.add(item)
        },
        async add (item) {
            const url = CONFIG.API.ROUTES.CART.ADD_QUOTE_ITEM
                .replace('{accountId}', this.quote.buyer.accountId)
                .replace('{cartId}', this.$route.params.cartId)
            this.addJob(url)
            await this.$api.post(url, item).then(({ data }) => {
                this.items = data.data
                this.$root.$emit('notification:global', {
                    message: 'Quote item successfully added',
                })
            }).catch(err => {
                if (CONFIG.DEBUG) {
                    console.log(err)
                    this.$root.$emit('notification:global', {
                        message: 'Quote Item cannot be added: ' + err,
                    })
                }
            }).finally(() => {
                this.finishJob(url)
                this.$refs.funnel.clearFunnel()
                this.showFunnelDialog = false
                this.draft = {}
            })
        },
        async removeItemFromCart (cartItemId) {
            const url = CONFIG.API.ROUTES.CART.ITEM_REMOVE
                .replace('{accountId}', this.$route.params.customerId)
                .replace('{cartId}', this.$route.params.cartId)
                .replace('{cartItemId}', cartItemId)

            this.addJob(url)
            await this.$api.post(url).then(({ data }) => {
                this.items = data.data
                this.$root.$emit('notification:global', {
                    message: 'Quote Item successfully removed',
                })
            }).catch(err => {
                if (CONFIG.DEBUG) {
                    console.log(err)
                    this.$root.$emit('notification:global', {
                        message: 'Quote Item cannot be removed: ' + err,
                    })
                }
            }).finally(() => {
                this.finishJob(url)
            })
        },
        updateQuoteItem (payload) {
            const url = CONFIG.API.ROUTES.CART.UPDATE.replace('{accountId}', this.$route.params.customerId).replace('{cartId}', this.$route.params.cartId)
            const cart = JSON.parse(JSON.stringify(this.items))
            const index = cart.cartItems.findIndex((e) => e.cartItemId === payload.cartItemId)
            this.$set(cart.cartItems, [index], payload)

            this.post(url, cart, 'Quote was updated').then((data) => {
                this.$set(this, 'items', data.data)
                this.$refs.editDialog.resetForm()
                this.unsetItem()
            })
        },
        updateQuote (payload = this.quote, message = 'Quote was updated') {
            const url = CONFIG.API.ROUTES.CART.UPDATE.replace('{accountId}', this.$route.params.customerId).replace('{cartId}', this.$route.params.cartId)
            this.loading = true
            this.post(url, payload, message).then((data) => {
                this.$set(this, 'items', data.data)
            }).finally(() => {
                this.loading = false
            })
        },
        shareWithSupplier () {
            const payload = Object.assign(JSON.parse(JSON.stringify(this.items)), { status: 'AWAITING_QUOTATION' })
            this.updateQuote(payload, 'Quote shared with supplier')
        },
        unsetItem () {
            this.$set(this, 'draft', {})
        },
        async setCartBillingInfo (address) {
            if (!this.hasPermission('setCartBillingInfo')) {
                return
            }
            this.addJob(this.billingUrl)

            await this.$api.post(this.billingUrl, address).then(({ data }) => {
                this.$set(this, 'billingAddress', data.data.billingInfo.address)
                this.$root.$emit('notification:global', {
                    message: 'Billing Info updated',
                })
            }).catch(err => {
                if (CONFIG.DEBUG) {
                    console.log(err)
                    this.$root.$emit('notification:global', {
                        message: 'Billing Info could not be updated',
                    })
                }
            }).finally(() => {
                this.finishJob(this.billingUrl)
            })
        },
        setShippingAddressForCartItems (address) {
            if (!this.hasPermission('setCartItemDeliveryInfo')) {
                return
            }
            this.isOrderlinesDialog = true
            this.selectedAddress = address
        },
        setAddresses () {
            this.loading = true
            const ids = Array.isArray(this.selectedCartItems) ? this.selectedCartItems.map((e) => e.cartItemId) : this.selectedCartItems.cartItemId
            const items = this.items.cartItems.filter((item) => ids.includes(item.cartItemId))

            items.forEach((item) => {
                const url = this.shippingUrl.replace('{cartItemId}', item.sourceId)

                this.addJob(url)

                this.$api.post(url, this.selectedAddress).then(({ data }) => {
                    this.$set(item, 'deliveryInfo', data.data.deliveryInfo)
                    this.$refs.table.refreshCells()
                    this.isOrderlinesDialog = false
                    this.$root.$emit('notification:global', {
                        message: 'Shipping address updated',
                    })
                }).catch(err => {
                    if (CONFIG.DEBUG) {
                        console.log(err)
                        this.isOrderlinesDialog = false
                        this.$root.$emit('notification:global', {
                            message: 'Shipping address could not be updated',
                        })
                    }
                }).finally(() => {
                    this.finishJob(url)
                    this.loading = false
                })

                if (!this.applicationVatProfiles) {
                    this.$root.$emit('notification:global', {
                        message: `Vat profiles for this shop are missing. We are trying to get them for you.`,
                        type: 'error',
                    })
                    this.fetchApplicationVatProfiles()
                    return false
                }

                const address = Object.assign(item.deliveryInfo.address, {})
                address.countryCode = this.countries.find((e) => e.countryName === address.country).countryShortCode
                if (!Object.keys(this.applicationVatProfiles).includes(address.countryCode)) {
                    this.$root.$emit('notification:global', {
                        message: `Shop does not ship to ${address.country}`,
                        type: 'error',
                    })
                    return false
                } else {
                    item.vatProfile = this.applicationVatProfiles[address.countryCode]
                }
            })
            this.updateQuote()
        },
        downloadAttachment (name) {
            const url = CONFIG.API.ROUTES.CART.GET_ATTACHMENT_DOWNLOAD_URL
            this.$root.$emit('notification:global', {
                message: 'Downloading file...',
            })
            this.post(url, { objectName: name }).then((data) => {
                window.open(data.url, '_blank')
            })
        },
        createNewItemFromCurrent (data) {
            this.showFunnelDialog = true
            this.draft.copiedItem = data
        },
        setQuoteAttachmentPermission (item, permissionType, $event) {
            const url = CONFIG.API.ROUTES.CART.SET_QUOTE_ATTACHMENT_PERMISSION.replace('{quoteId}', this.$route.params.cartId).replace('{quoteAttachmentId}', item.quoteAttachmentId)
            const payload = {
                accountId: this.$route.params.customerId,
                permissionType,
                action: $event ? 'GRANT' : 'REVOKE',
            }
            this.loading = true
            this.post(url, payload, 'Attachment permission updated').finally(() => {
                this.loading = false
            })
        },
        attachToCart (payload) {
            const url = CONFIG.API.ROUTES.CART.ADD_ATTACHMENT.replace('{cartId}', this.$route.params.cartId).replace('{accountId}', this.$route.params.customerId)
            this.post(url, [payload], 'Attachment was uploaded').then(({ data }) => {
                this.items.attachments.push(data.quoteAttachment)
                this.$refs.uploader.$refs.dropper.resetFiles()
            })
        },
        removeAttachment (item) {
            const url = CONFIG.API.ROUTES.CART.REMOVE_ATTACHMENT.replace('{cartId}', this.$route.params.cartId).replace('{quoteAttachmentId}', item.quoteAttachmentId)
                .replace('{accountId}', this.$route.params.customerId)
            this.loading = true
            this.post(url, {}, 'Attachment removed').then(({ data }) => {
                const index = this.items.attachments.findIndex((e) => e.quoteAttachmentId === item.quoteAttachmentId)
                this.items.attachments.splice(index, 1)
            }).finally(() => {
                this.loading = false
            })
        },
        listen () {
            this.$router.push({
                name: 'QuotesWrapper',
            })
        },
    },
    created () {
        this.checkShop(this.$route.params.applicationId)
        this.fetchCurrencyConversionRates()
        this.fetchCustomer({
            accountId: this.$route.params.customerId,
        })

        this.url = CONFIG.API.ROUTES.CART.GET.replace('{accountId}', this.$route.params.customerId).replace('{cartId}', this.$route.params.cartId)
    },
    mounted () {
        this.$bus.$on('shopWasChangedOnUniqueView', this.listen)
    },
    destroyed () {
        this.$bus.$off('shopWasChangedOnUniqueView', this.listen)
    },
}
</script>

<style lang="scss" scoped>
    .c-quote-edit {
        &__btn {
            width: 100%;
        }

        &__action-dropdown {
            float: right;
        }

        &__attachments {
            span {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        &__item-info {
            h3 {
                margin-top: 0;
            }
            ul {
                list-style: none;
                padding: 0;

                li {
                    padding: 4px 0;
                }
            }
        }
    }
    .add-address-button {
        float: right;
    }
</style>
