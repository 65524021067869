<template>
    <div>
        <span v-if="!agents">
            Loading agents...
        </span>
        <pretty-select
            v-else
            :placeholder="placeholder"
            :options="filteredAgents"
            :get-option-label="getOptionLabel"
            :reduce="agent => agent.accountId"
            :value="safeValue"
            v-on="$listeners"
            :disabled="isReadonly"
            class="mb-3"
        ></pretty-select>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import PrettySelect from '../../utils/PrettySelect'
export default {
    name: 'AssignedAgent',
    components: { PrettySelect },
    props: {
        value: {},
        placeholder: {
            default: 'Assigned agent',
        },
        getOptionLabel: {
            default: () => agent => `${agent.firstName} ${agent.infix || ''} ${agent.lastName}`,
        },
        agentFilter: {
            default: () => () => true,
        },
        isReadonly: {
            default: false,
        },
    },
    computed: {
        ...mapState('agents', ['agents']),
        filteredAgents () {
            return JSON.parse(JSON.stringify(this.agents)).filter(this.agentFilter).sort((agentA, agentB) => {
                return agentA.firstName.localeCompare(agentB.firstName)
            })
        },
        safeValue () {
            if (!this.agents || !this.agents.some(agent => agent.accountId === this.value)) {
                return null
            }
            return this.value
        },
    },
    methods: {
        ...mapActions('agents', ['fetchAllAgents']),
    },
    created () {
        this.fetchAllAgents()
    },
}
</script>
