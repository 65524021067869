<template>
    <div>
        <div
            class="o-filedropper"
            :class="{'o-filedropper--is-highlighted': highlighted}"
            @dragenter.prevent.stop="highlight"
            @dragover.prevent.stop="highlight"
            @dragleave.prevent.stop="unhighlight"
            @drop="dropped"
            @click="$refs.fileinput.click()"
        >
            <input data-e2e="orderlineArtworkUploadInput" @change="selected" type="file" name="file" multiple ref="fileinput" class="o-filedropper__input" />
            <h4 class="o-filedropper__note">
                <i class="fas fa-cloud-upload-alt"></i>
                UPLOAD FILES
            </h4>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    name: 'FileArea',
    data () {
        return {
            highlighted: false,
        }
    },
    props: {
        uploadUrl: {
            type: String,
        },
        isDirectUpload: {
            default: false,
        },
    },
    computed: {
        ...mapState('order', ['orderLine']),
    },
    methods: {
        highlight () {
            this.highlighted = true
        },

        unhighlight () {
            this.highlighted = false
        },

        selected ($event) {
            this.handleFilesSelected([...$event.target.files])
        },

        dropped ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            this.handleFilesSelected([...$event.dataTransfer.files])
        },
        handleFilesSelected (files) {
            const currentTimestamp = Number((new Date()).getTime())

            const bulkMap = files.map((file, index) => {
                const fileTimestamp = currentTimestamp + index
                return {
                    originalFilename: file.name,
                    objectName: `${fileTimestamp}_${file.name}`,
                    file,
                }
            })

            bulkMap.forEach(file => {
                this.$emit('uploadUpdate', {
                    type: 'start',
                    file,
                })
            })

            if (!this.isDirectUpload) {
                this.getUploadLinks(bulkMap.map(file => file.objectName)).then(({ data }) => {
                    const fileUploadDetails = data.map(uploadDetail => {
                        return {
                            ...uploadDetail,
                            ...bulkMap.find(mapItem => uploadDetail.objectName === mapItem.objectName),
                        }
                    })
                    this.processFiles(fileUploadDetails)
                })
                this.unhighlight()

                return
            }

            this.directUpload(bulkMap)
            this.unhighlight()
        },
        getUploadLinks (files) {
            const url = this.uploadUrl

            return this.$api.post(url, {
                files,
            })
        },

        processFiles (fileUploadDetails) {
            fileUploadDetails.forEach(details => {
                const config = {
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        this.$emit('uploadUpdate', {
                            type: 'progress',
                            file: details,
                            percentCompleted,
                        })
                    },
                }

                axios.put(details.url, details.file, config).then(() => {
                    this.$emit('uploadUpdate', {
                        type: 'completed',
                        file: details,
                    })
                }).catch(error => {
                    console.error(error)
                }).finally(() => {
                    this.finishJob(details.objectName)
                })
            })
        },

        directUpload (bulkMap) {
            const formData = new FormData()

            let fileList = []
            bulkMap.forEach((item) => {
                fileList.push(item.file)
            })

            if (!fileList.length) return

            Array
                .from(Array(fileList.length).keys())
                .map(x => {
                    formData.append('files[]', fileList[x], fileList[x].name)
                })

            const config = {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    bulkMap.forEach((item) => {
                        this.$emit('uploadUpdate', {
                            type: 'progress',
                            file: item,
                            percentCompleted,
                        })
                    })
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
            formData.append('orderLineNumber', this.orderLine.orderLineNumber)
            this.$api.post(this.uploadUrl, formData, config).then(({ data }) => {
                bulkMap.forEach((item) => {
                    this.$emit('uploadUpdate', {
                        type: 'completed',
                        file: item,
                    })
                })
                this.$emit('uploadUpdate', {
                    type: 'finished',
                    data,
                })
            })
        },
    },

}
</script>

<style lang="scss" scoped>
    .o-filedropper {
        display: flex;
        align-items: center;
        background-color: #F9F9F9;
        border: 2px dashed #999;
        border-radius: 3px;
        padding: 10px 40px;
        height: 100px;
        position: relative;
        transition: all 200ms;
        cursor: pointer;

        &--is-highlighted {
            filter: brightness(90%);
            border: 2px solid darken(#999, 10%);
        }

        &__input {
            position: absolute;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 0;
            height: 0;
        }

        i {
            font-size: 40px !important;
            height: auto;
            width: auto;
            margin-right: 15px;
            margin-top: -5px;
            vertical-align: middle;
        }

        &__note {
            font-weight: bold;
            font-size: 16px;
            flex: 1;
            text-align: center;
        }
    }
</style>
