<template>
    <ul class="delivery-address" v-if="address">
        <li>{{ address.firstName }} {{ address.lastName }}</li>
        <li>{{ address.address }} {{ address.addressAdditional }}</li>
        <li>{{ address.zipCode }} {{ address.city }}</li>
        <li>{{ address.country }}</li>
    </ul>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
    name: 'DeliveryAddress',
    computed: {
        address () {
            if (!this.params.data.deliveryInfo) {
                return false
            }
            return this.params.data.deliveryInfo.address
        },
    },
})
</script>

<style lang="scss" scoped>
    .delivery-address {
        list-style: none;
        margin: 0;
        padding: 0;
    }
</style>
