<template>
    <div>
        <h3 class="font-weight-normal mt-1">Customer</h3>
        <div>
            <p data-e2e="customerName" v-if="Object.keys(customer).length">{{ customer.firstName }} {{ customer.lastName }}
                (<router-link :to="routeCustomer(customer.accountId, customer.application.applicationId)">{{ customer.accountNumber || 'link to profile' }}</router-link>)
            </p>
            <p  v-if="customer.profile && !Array.isArray(customer.profile)">Number of orders: {{ customer.profile.totalOrderCount }}</p>
            <p  v-if="customer.profile && !Array.isArray(customer.profile)">Total amount spent: <strong><currency :value="customer.profile.lifetimeRevenue" :type="customer.currency"></currency></strong></p>
            <div v-if="Object.keys(form).length">
                <hr>
                <p><b>Invoice Address</b> <span class="c-card__edit" data-e2e="customerInvoiceAddressEdit" v-if="!isNotEditable && (!order.payments || hasPermission('setOrderBillingInfo'))" @click="edit">Edit</span></p>
                <table data-e2e="customerInvoiceFullAddress">
                    <tr>
                        <td>{{ form.firstName }} {{ form.lastName }}</td>
                    </tr>
                    <tr>
                        <td>{{ form.companyName }}</td>
                    </tr>
                    <tr>
                        <td data-e2e="customerInvoiceAddress">{{ form.address }} {{ form.addressAdditional }}</td>
                    </tr>
                    <tr>
                        <td data-e2e="customerInvoiceAddressCity">{{ form.zipCode }} {{ form.city }}</td>
                    </tr>
                    <tr>
                        <td data-e2e="customerInvoiceAddressCountry">{{ form.country }}</td>
                    </tr>
                    <template v-if="form.additionalInfo && form.additionalInfo.eInvoice">
                        <tr v-if="form.additionalInfo.eInvoice.fiscalCode">
                            <td>Fiscal code: {{ form.additionalInfo.eInvoice.fiscalCode }}</td>
                        </tr>
                        <tr v-if="form.additionalInfo.eInvoice.pecEmail">
                            <td>{{ form.additionalInfo.eInvoice.pecEmail }}</td>
                        </tr>
                        <tr v-if="form.additionalInfo.eInvoice.uniqueCode">
                            <td>Unique code: {{ form.additionalInfo.eInvoice.uniqueCode }}</td>
                        </tr>
                    </template>
                </table>
                <hr class="c-data-table__inner-divider" />
                <p><b>Contact Information</b></p>
                <table>
                    <tr>
                        <td>Mobile:</td>
                        <td>{{ form.phone }} (mobile)</td>
                    </tr>
                    <tr>
                        <td>Email:</td>
                        <td>{{ customer.email }}</td>
                    </tr>
                </table>
                <div>
                    <mercur-dialog :is-open.sync="isEditing">
                        <h3 data-e2e="setInvoiceAddressModal">Set Invoice address</h3>
                        <p>
                            {{ form.firstName }} {{ form.lastName }} <br>
                            <span v-if="form.companyName">{{ form.companyName }} <br></span>
                            {{ form.address }} {{ form.addressAdditional }} <br>
                            {{ form.zipCode }} {{ form.city }} <br>
                            {{ form.country }}
                        </p>
                        <pretty-select
                            data-e2e="invoiceAddressSelector"
                            v-if="addresses.length"
                            label="label"
                            placeholder="Addresses"
                            :options="addresses"
                            v-model="selectedAddress"
                        ></pretty-select>
                        <template #footer>
                            <div class="text-right">
                                <mercur-button class="btn" @click.native="cancel" :disabled="loading">Cancel</mercur-button>
                                <mercur-button data-e2e="invoiceNewAddressSaveButton" class="btn btn-primary" @click.native="updateAddress" :disabled="loading">Save</mercur-button>
                            </div>
                        </template>
                    </mercur-dialog>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import PrettySelect from '@/components/utils/PrettySelect'
export default {
    name: 'CustomerInfo',
    components: { PrettySelect },
    props: {
        billingAddress: {
            default: {},
        },
        action: {
            default: null,
        },
        isNotEditable: {
            default: false,
            type: Boolean,
        },
    },
    data () {
        return {
            isEditing: false,
            form: {},
            selectedAddress: null,
            loading: false,
        }
    },
    watch: {
        billingAddress: {
            handler (value) {
                this.$set(this, 'form', value)
            },
            immediate: true,
        },
    },
    computed: {
        ...mapState('order', ['order']),
        ...mapState('customer', ['customer', 'addresses']),
    },
    methods: {
        routeCustomer (id, applicationId) {
            return {
                name: 'CustomerView',
                params: {
                    customerId: id,
                    applicationId: applicationId,
                },
            }
        },
        edit () {
            this.isEditing = true
        },
        cancel () {
            this.isEditing = false
            this.selectedAddress = null
        },
        updateAddress () {
            this.loading = true
            this.post(this.action, this.selectedAddress, 'Billing address was updated').then(({ data }) => {
                this.$set(this, 'form', data.billingInfo.address)
            }).finally(() => {
                this.isEditing = false
                this.loading = false
            })
        },
    },
}
</script>
