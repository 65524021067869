<template>
    <div>
        <template v-if="hasPermission('MerchantCentral/listAddresses')">
            <merchant-data-table style="height: 250px; min-height: unset;" class="shadow" :url="url" :options="mergedOptions" @rowClicked="rowClicked" ref="table" @paginationSet="updatePagination"></merchant-data-table>
        </template>
        <template v-else>
            Not allowed to see this view
        </template>
        <div>
            <mercur-dialog :is-open.sync="isConfirmDialogOpen">
                <div class="mt-3 mb-1">Are you sure you want to remove this address?</div>
                <template #footer>
                    <div class="text-right">
                        <mercur-button class="btn" @click.native="isConfirmDialogOpen = false">Cancel</mercur-button>
                        <mercur-button class="btn" @click.native="deleteAddress" :disabled="isLoading">Confirm</mercur-button>
                    </div>
                </template>
            </mercur-dialog>
        </div>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import MerchantDataTable from '@/components/elements/MerchantDataTable'
import { mapState } from 'vuex'
import HelperMethods from '@/components/utils/HelperMethods'
export default {
    name: 'CustomerAddresses',
    mixins: [ HelperMethods ],
    components: { MerchantDataTable },
    props: {
        customerId: {
            required: true,
        },
        organisationId: {
            required: true,
        },
        customOptions: {
            default: null,
        },
        canSetDefault: {
            default: false,
        },
        isNotEditable: {
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            totalPages: 1,
            currentPage: 1,
            isConfirmDialogOpen: false,
            isLoading: false,
            activeAddress: null,
            options: {
                columns: {
                    'Name': { field: ['firstName', 'lastName'] },
                    'Address': { field: ['address', 'addressAdditional', 'houseNumber', 'houseNumberAdditional', 'zipCode', 'city'] },
                    'Country': { field: 'country' },
                    'Phone Number': { field: 'phone' },
                },
                actions: {
                    type: 'dropdown',
                    attr: {
                        'data-e2e': 'menuAddressSelect',
                    },
                    items: [
                        {
                            text: 'Edit',
                            onClick: ({ data }) => {
                                this.editAddress(data)
                            },
                            isHidden: () => {
                                return this.isNotEditable || !this.hasPermission('updateAddress')
                            },
                        },
                        {
                            text: 'Delete',
                            onClick: ({ data }) => {
                                this.triggerDeleteDialog(data)
                            },
                            isDisabled: () => {
                                return this.isLoading || this.disabled
                            },
                            isHidden: () => {
                                return this.isNotEditable || !this.hasPermission('removeAddress')
                            },
                        },
                        {
                            text: 'Set as billing address',
                            attr: {
                                'data-e2e': 'billingAddressOption',
                            },
                            onClick: ({ data }) => {
                                this.setAsDefaultBillingAddress(data)
                            },
                            isDisabled: () => {
                                return this.isLoading || this.disabled
                            },
                            isHidden: ({ data }) => {
                                if (!data.addressId) {
                                    return true
                                }

                                if (this.canSetDefault && !this.customer.defaultBillingAddress) {
                                    return false
                                }

                                return !this.canSetDefault || !this.hasPermission('setDefaultBillingAddress')
                            },
                        },
                        {
                            text: 'Set as shipping address',
                            attr: {
                                'data-e2e': 'shippingAddressOption',
                            },
                            onClick: ({ data }) => {
                                this.setAsDefaultShippingAddress(data)
                            },
                            isDisabled: () => {
                                return this.isLoading || this.disabled
                            },
                            isHidden: ({ data }) => {
                                if (!data.addressId) {
                                    return true
                                }

                                if (this.canSetDefault && !this.customer.defaultShippingAddress) {
                                    return false
                                }

                                return !this.canSetDefault || !this.hasPermission('setDefaultShippingAddress')
                            },
                        },
                    ],
                },
                paginationPageSize: 10,
                cacheBlockSize: 10,
                defaultColDef: {
                    filter: false,
                },
            },
        }
    },
    computed: {
        ...mapState('customer', ['customer']),
        mergedOptions () {
            return {
                ...this.options,
                ...this.customOptions,
            }
        },
        url () {
            return CONFIG.API.ROUTES.CUSTOMERS.GET.ADDRESSES
                .replace('{customerId}', this.customerId)
                .replace('{organisationId}', this.organisationId)
        },
    },
    methods: {
        rowClicked (address) {
            this.$emit('rowClicked', address)
        },
        editAddress (data) {
            const address = JSON.parse(JSON.stringify(data))
            this.$emit('editAddress', address)
        },
        updatePagination (data) {
            this.totalPages = data.totalPages
            this.currentPage = data.currentPage
        },
        triggerDeleteDialog (addressId) {
            this.isConfirmDialogOpen = true
            this.activeAddress = addressId
        },
        deleteAddress () {
            const url = CONFIG.API.ROUTES.CUSTOMERS.ADDRESS.REMOVE
                .replace('{customerId}', this.$route.params.customerId)
                .replace('{organisationId}', this.customer.organisation.organisationId)
                .replace('{addressId}', this.activeAddress.addressId)

            this.isLoading = true
            this.post(url, {}, 'Revoke success').then(() => {
                this.$refs.table.refreshGrid()
                this.isConfirmDialogOpen = false
            }).finally(() => {
                this.isLoading = false
            })
        },
        setAsDefaultBillingAddress (data) {
            this.$emit('setBilling', data)
        },
        setAsDefaultShippingAddress (data) {
            if (!this.verifyShippingAddress(data)) {
                return
            }

            this.$emit('setShipping', data)
        },
    },
}
</script>
