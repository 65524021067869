<template>
    <div>
        <file-dropper :uploadUrl="uploadUrl" @uploaded="appendUploaded" @fileRemoved="removeFile" ref="dropper"></file-dropper>
    </div>
</template>

<script>
import FileDropper from '@/components/utils/FileDropper'
import CONFIG from '@root/config'
export default {
    name: 'QuoteUploader',
    components: { FileDropper },
    props: {
        customer: {
            type: Object,
            required: true,
        },
    },
    computed: {
        uploadUrl () {
            if (!this.customer) {
                return
            }
            return CONFIG.API.ROUTES.CART.UPLOAD_ATTACHMENTS.replace('{organisationId}', this.customer.organisation.organisationId)
        },
    },
    methods: {
        appendUploaded ($event) {
            const payload = {
                uploaderType: 'MERCHANT',
                originalFileName: $event.file.objectName,
                objectName: $event.file.fullPath,
            }
            this.$emit('uploaded', payload)
        },
        removeFile ($event) {
            this.$emit('remove', $event)
        },
    },
}
</script>
