<template>
    <div>
        <p class="c-attribute-list" v-for="(attribute, key) in attributes" :key="key">
            <template v-if="attribute.attributeName">
                <span class="c-attribute-list__attribute">{{ attribute.attributeName }}:</span> {{ attribute.attributeOption }}
            </template>
            <template v-else>
                <span class="c-attribute-list__attribute">{{ key }}:</span> {{ attribute }}
            </template>
        </p>
        <p class="c-attribute-list">
            <span class="c-attribute-list__attribute">quantity: </span> {{ params.data.product.quantity }}
        </p>
    </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
    name: 'AttributeList',
    computed: {
        attributes () {
            return this.params.data.product.attributes
        },
    },
})
</script>

<style lang="scss" scoped>
    .c-attribute-list {
        margin: 0;
        font-weight: bold;

        &__attribute {
            font-weight: normal;
            display: inline-block;
        }
    }
</style>
